* {
  box-sizing:border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:before,
*:after {
	box-sizing:border-box;
}

html{
    height: 100%;
    -ms-text-size-adjust: 100%;
  	-webkit-text-size-adjust: 100%;
  	box-sizing: border-box;
}


*:focus {
	outline: none;
	-moz-outline-style: none;
}

:-moz-any-link:focus {
	outline: 1px dotted invert;
}

:-moz-any-link:focus {
	outline: none;
}

textarea,input {
	box-shadow:none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

a{
	transition: color 0.3s linear;
	text-decoration: none;
	&:not([class]){
		color: #6773E5;
		text-decoration: underline;
		&:hover{
			color: #2D3ABF;
		}
	}
}


body {
	margin: 0;
	height: 100%;
	background: $default-site-bg;
	-webkit-font-smoothing: antialiased;
	font-size: $default-size-font;
	// font-family: $default-font;
	color: $base-color;
	font-weight: 300;
	display: flex;
  	flex-direction: column;
}

textarea,input {
	box-shadow: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
input[type="text"],
input[type="email"],
input[type="password"]{
	width: 100%;
	display: inline-block;
	vertical-align: middle;
	color: $alt-color;
	padding: 17px 20px;
	background: #FFFFFF;
	border: 1px solid #E8E8E8;
	border-radius: 12px;
	transition: all .25s ease;
	font-size: 14px;
	&:hover{
		border: 1px solid #555B6F;
	}
	&:not(:placeholder-shown){
		border: 1px solid #6773E5;
	}
}

::-webkit-input-placeholder {color:#555B6F;transition:all 0.3s ease;}
::-moz-placeholder          {color:#555B6F;transition:all 0.3s ease;}
:-moz-placeholder           {color:#555B6F;transition:all 0.3s ease;}
:-ms-input-placeholder      {color:#555B6F;transition:all 0.3s ease;}



:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder          {color: transparent}
:focus:-moz-placeholder           {color: transparent}
:focus:-ms-input-placeholder      {color: transparent}


h1{
	margin: 0 0 20px 0;
	color: $alt-color;
	font-size: 36px;
}
h2{
	margin: 0 0 26px 0;
	color: $alt-color;
	font-size: 36px;
}
h3{
	margin: 0 0 20px 0;
	color: $alt-color;
	font-size: 28px;
}

h4{
	margin: 0 0 20px 0;
	color: $alt-color;
	font-size: 22px;
}

ul{
	margin: 0 0 20px 0;
    padding: 0 0 0 20px;
	li{
		margin: 0 0 13px 0;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

ol{
	margin: 0 0 20px 0;
    padding: 0 0 0 20px;
	li{
		margin: 0 0 13px 0;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

p{
	margin: 0 0 18px;
	line-height: 1.4;
}