.page-footer{
	flex-shrink: 0;
	padding: 50px 0;
	background: #fff url(../img/content/f-bg.png) right bottom/contain no-repeat;
	&__wrap{
		display: flex;
		justify-content: space-between;
	}
	&__more{
		margin-bottom: 20px;
		font-size: 14px;
	}
}

.copyright{
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 20px;
	color: $alt-color;
}
.page-footer-links{
	display: flex;
	flex-wrap:wrap;
	&__item{
		margin-right: 32px;
		color: $base-color;
		font-size: 12px;
		text-decoration: none;
		&:last-child{
			margin-right: 0;
		}
	}
}