
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {

}

//MEDIA
@media screen and (max-width: 1320px){

}

@media screen and (max-width: 992px){
    .page-top{
        padding: 80px 0;
        .container-fluid{
            padding: 0 120px;
        }
    }
    .page-top__wrap{
        flex-wrap:wrap;
    }
    .page-top__info {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 48px;
        text-align: center;
    }
    .page-top__img {
        width: 100%;
        flex: inherit;
        justify-content: center;
    }
    .page-footer{
        padding: 20px 0;
    }
    .page-footer__wrap{
        flex-wrap: wrap;
        justify-content: center;
    }
    .page-footer__buttons{
        order: 1;
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        .login{
            margin-right: 0;
        }
        .btn{
            margin-right: 25px;
        }
    }
    .page-footer__info{
        width: 100%;
        order: 2;
        text-align: center;
    }
    .page-footer-links__item {
        width: 100%;
        margin: 0 0 16px 0;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .view__info{
        padding-right: 40px;
    }
    .view__item:nth-child(even) .view__info {
        padding-left: 40px;
    }
    .view__item{
        margin-bottom: 100px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 767px){
    .page-top{
        .container-fluid{
            padding: 0 20px;
        }
    }
    .view{
        padding: 80px 0;
    }
    .view__item{
        flex-wrap: wrap;
    }
    .view__info {
        padding-right: 0;
        width: 100%;
        margin-bottom: 40px;
        h3{
            br{
                display: none;
            }
        }
    }
    .view__img{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .view__item:nth-child(even) .view__info {
        padding-left: 0;
        order: 1;
    }
    .view__item:nth-child(even) .view__img{
        order: 2;
    }
    .page{
        flex-wrap: wrap;
    }
    .page__aside {
        margin-right: 0;
        width: 100%;
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 600px){
    .page__aside {
        margin-bottom: 32px;
    }
    h1{
        font-size: 28px;
    }
    h2{
        font-size: 28px;
    }
    h3{
        font-size: 22px;
    }
    .page-header{
        .login,.btn{
            text-transform: capitalize;
            span{
                display: none;
            }
        }
        .login{
            margin-right: 15px;
        }
    }
    .benefits{
        padding: 40px 0;
    }
    .benefits__item{
        width: 100%;
    }
    .view {
        padding: 60px 0;
    }
    .view__item{
        margin-bottom: 80px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
