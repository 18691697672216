

.layout{
  	flex: 1 0 auto;
}


.wrapper{

}
.container-fluid{
	width: 100%;
	max-width: 1080px;
    padding: 0 20px;
	position: relative;
	margin: 0 auto;
}


.page-content{

}

.btn{
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	background: #6773E6;
	border-radius: 12px;
	padding: 12px 20px;
	transition: all .25s ease;
	&:hover{
		background: #414ED1;
	}
	&:active{
		background: #2D3ABF;
	}
}


.page-top{
	padding: 140px 0 130px;
	background: linear-gradient(225deg, #EDF3FF 0%, #FFFFFF 100%);
	&__wrap{
		display: flex;
		align-items: center;
	}
	&__info{
		width: 440px;
		min-width: 440px;
		margin-right: 30px;
	}
	&__img{
		flex: 1;
		justify-content: flex-end;
		display: flex;
		img{
			display: block;
			max-width: 100%;
		}
	}
	&__pre{
		font-size: 16px;
		font-weight: 700;
		color: #6773E6;
		margin-bottom: 8px;
	}
	&__txt{
		font-size: 16px;
		line-height: 1.4;
		margin-bottom: 20px;
	}
}
.page-top-form{
	&__item{
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__submit{
		background: #6773E6;
		border-radius: 12px;
		color: #fff;
		text-align: center;
		display: block;
		width: 100%;
		padding: 18px 20px;
		font-weight: 700;
		font-size: 14px;
		border:0;
		transition: all .25s ease;
		&:hover{
			background: #414ED1;
		}
		&:active{
			background: #2D3ABF;
		}
	}
}



.benefits{
	background: #F7F7F9;
	padding: 70px 0;
	&__list{
		display: flex;
		flex-wrap:wrap;
		margin: 0 -12px -24px;
	}
	&__item{
		width: 50%;
		margin-bottom: 24px;
		padding: 0 12px;
	}
	&__in{
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		border-radius: 32px;
		padding: 24px;
	}
	&__icon{
		height: 56px;
		margin-bottom: 16px;
		img{
			display: block;
			max-height: 100%;
		}
	}
	&__title{
		font-weight: 800;
		font-size: 22px;
		margin-bottom: 16px;
		color: $alt-color;
	}
	&__txt{
		font-size: 16px;
	}
}

.benefits-l{
	column-count: 2;
	padding-top: 2px;
	&__item{
		margin-bottom: 2px;
	}
}

.view{
	padding: 146px 0 164px;
	&__item{
		display: flex;
		align-items: center;
		margin-bottom: 170px;
		&:last-child{
			margin-bottom: 0;
			.view__img{
				position: relative;
				&:before{
					content: "";
					display: inline-block;
					width: 454px;
					height: 456px;
					background: url(../img/content/ellipse.png);
					position: absolute;
					left: -205px;
    				top: -83px;
				}
				img{
					position: relative;
					z-index: 1;
				}
			}
		}
		&:nth-child(even){
			.view__img{
				order: 1;
			}
			.view__info{
				order: 2;
				padding-right: 0;
				padding-left: 113px;
			}
		}
	}
	&__img{
		width: 50%;
		img{
			display: block;
			max-width: 100%;
		}
	}
	&__info{
		width: 50%;
		padding-right: 113px;
		ul{
			margin: 0;
			padding: 10px 0 0 0;
			li{
				list-style-type: none;
				margin: 0 0 11px 0;
				&:before{
					content: "";
					display: inline-block;
					width: 18px;
					height: 18px;
					background: url(../img/svg/li.svg) no-repeat;
					margin: 0px 8px -2px 0;
				}
			}
		}
	}
}



.page{
	padding: 40px 0;
	display: flex;
	&__view{
		flex:1;
	}
	&__aside{
		width: 290px;
		margin-right: 64px;
	}
}
.aside-item{
	border-bottom: 1px solid #E8E8E8;
	
	&:last-child{
		border-bottom: 0;
	}
	&__link{
		font-size: 16px;
		color: #555B6F;
		display: block;
		text-decoration: none;
		padding: 16px 0;
		&.active{
			color: $alt-color;
			font-weight: 700;
		}
	}
	&:first-child{
		.aside-item__link{
			padding-top: 0;
		}
	}
}

.page__aside{
	&__in{
		position: sticky;
		top: 20px;
	}
}