.page-header{
    padding: 12px 0;
    // position: sticky;
    // top: 0;
    z-index: 9;
    background: #fff;
    &__wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__buttons{
        white-space: nowrap;
    }
}

.logo a{
    font-weight: 800;
    font-size: 24px;
    color: $alt-color;
    line-height: 1;
    margin-right: 20px;
    text-decoration: none;
}

.login{
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: $alt-color;
    margin-right: 27px;
}